import MonitoringService from "@lib/MonitoringService";
import pRetry from "p-retry";
import { ProfileCompact } from "@/api/entities/profile";

function trackSearchImpression(
  userId: ProfileCompact["user_id"],
  rank: string,
) {
  return withRetry(() => createSearchImpression(userId, rank));
}

function trackProfileVisit(profileId: ProfileCompact["id"]) {
  return withRetry(() => createProfileVisit(profileId));
}

function withRetry(f: () => PromiseLike<unknown>) {
  /* Exponential backoff with options to set boundaries */
  return pRetry(f, {
    maxRetryTime: 60 * 60 * 1000 /* 1 hour */,
    // The number of milliseconds before starting the first retry
    minTimeout: 1000 /* 1 second */,
  }).catch((e) =>
    MonitoringService.captureException(e, {
      extra: {
        impressions: `Failed to track therapist's statistics`,
      },
    }),
  );
}

function createSearchImpression(
  userId: ProfileCompact["user_id"],
  rank: string,
) {
  return fetch(
    `${process.env.NEXT_PUBLIC_APP_API_URL}/analytics/search/impression`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: userId,
        rank: rank,
      }),
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error("Seach Impression tracking failed");
    }
  });
}

async function createProfileVisit(profileId: string) {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APP_API_URL}/profile/${profileId}/create_visit`,
    {
      method: "POST",
    },
  );
  if (!response.ok) {
    throw new Error("Create profile visit failed");
  }
}

export { trackProfileVisit, trackSearchImpression };
