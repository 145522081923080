import { Section, Section as ThemesSection } from "@radix-ui/themes";
import styles from "./DirectoryResultsContainer.module.scss";
import React from "react";

interface ThemesSectionProps
  extends React.ComponentPropsWithoutRef<typeof ThemesSection> {}

interface DirectoryResultsContainerProps extends ThemesSectionProps {}
function DirectoryResultsContainer(props: DirectoryResultsContainerProps) {
  return (
    <Section
      key="directory-cards"
      className={styles.directoryCardsListContainer}
      {...props}
    >
      {props.children}
    </Section>
  );
}

export { DirectoryResultsContainer };
