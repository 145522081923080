"use client";
import React, { useEffect } from "react";
import { DirectoryPageViewedProps } from "@/analytics/analytics.directory";
import { directory } from "@/analytics";

const DirectoryPageViewTracker: React.FC<DirectoryPageViewedProps> = (
  props,
) => {
  useEffect(() => {
    directory.directoryPageViewed(props);
  });
  return <></>;
};

export default DirectoryPageViewTracker;
