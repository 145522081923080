import { render, ServerResponse } from "./api";
import { ProfileCompact } from "./entities/profile";

type SearchOption = {
  search_filter_id: string;
  analytics_id: string;
  name: string;
};
type SearchSelect<T = object> = {
  all: (SearchOption & T)[];
  common: (SearchOption & T)[];
};
type SearchSelectWithSlug<T = object> = SearchSelect<{ slug: string } & T>;
type SearchFiltersOptions = {
  specialities: SearchSelectWithSlug;
  approaches: SearchSelectWithSlug;
  locations: SearchSelectWithSlug;
  languages: SearchSelectWithSlug;
  identities: SearchSelect;
  genders: SearchSelect;
  insurances: SearchSelect;
  formats: SearchSelect;
};
const EMPTY_SELECT = Object.freeze({ all: [], common: [] });

async function searchOptions(
  locale: string,
): Promise<ServerResponse<SearchFiltersOptions>> {
  return await render<SearchFiltersOptions>(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/directory/filters_v2?locale=${locale}`,
    ),
    (payload) => ({
      specialities: payload.specialities || EMPTY_SELECT,
      approaches: payload.approaches || EMPTY_SELECT,
      formats: payload.formats || EMPTY_SELECT,
      locations: payload.locations || EMPTY_SELECT,
      languages: payload.languages || EMPTY_SELECT,
      identities: payload.identities || EMPTY_SELECT,
      genders: payload.genders || EMPTY_SELECT,
      insurances: payload.insurances || EMPTY_SELECT,
    }),
  );
}

type SearchFilterParams = {
  specialities?: string[];
  approaches?: string[];
  formats?: string[];
  locations?: string[];
  languages?: string[];
  identities?: string[];
  insurances?: string[];
  genders?: string[];
  price?: { min: number; max: number };
  isEap?: true;
  keywords?: string;
  zipcodes?: string[];
};

type SearchParams = {
  filters?: SearchFilterParams;
  seed?: string;
  page?: number;
  page_size?: number;
  spotlight_profile_ids?: string[];
};

type SearchResponseResult = {
  seed: string;
  page: number;
  total_matches: number;
  total_pages: number;
  is_fallback: boolean;
  spotlights?: ProfileCompact[];
  profiles: ProfileCompact[];
  ranker_version: number;
};

type SearchResponse = {
  params: SearchParams;
  result: SearchResponseResult;
};

type FilterParams = {
  filter_online?: boolean;
  filter_eap?: boolean;
  filter_specialities?: string[];
  filter_approaches?: string[];
  filter_formats?: string[];
  filter_locations?: string[];
  filter_languages?: string[];
  filter_genders?: string[];
  filter_identities?: string[];
  filter_insurances?: string[];
  filter_price_range?: number[];
  filter_keywords?: string;
  filter_zipcodes?: string[];
};

type SearchQueryBody = FilterParams & {
  page?: number;
  page_size?: number;
  seed?: string;
  spotlight_profile_ids?: string[];
};

type SearchLocalQueryBody = FilterParams;

async function search(
  params: SearchParams,
  locale: string,
  signal?: AbortSignal, // Allows us to cancel / requests made in quick succession
): Promise<ServerResponse<SearchResponse>> {
  const requestSearchParams: SearchQueryBody = {
    page: params.page,
    page_size: params.page_size,
    seed: params.seed,
    spotlight_profile_ids: params.spotlight_profile_ids,
    filter_eap: params.filters?.isEap,
    filter_specialities: params.filters?.specialities,
    filter_approaches: params.filters?.approaches,
    filter_formats: params.filters?.formats,
    filter_locations: params.filters?.locations,
    filter_languages: params.filters?.languages,
    filter_genders: params.filters?.genders,
    filter_identities: params.filters?.identities,
    filter_insurances: params.filters?.insurances,
    filter_price_range: params.filters?.price
      ? [params.filters.price.min, params.filters.price.max]
      : undefined,
    filter_keywords: params.filters?.keywords,
    filter_zipcodes: params.filters?.zipcodes,
  };

  return render(
    fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/directory/search?locale=${locale}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestSearchParams),
        signal,
      },
    ),
    mapSearchResponse,
  );
}

type SearchLocalResponse = ProfileCompact[];

async function searchForLocalTherapists(
  params: SearchFilterParams,
  signal?: AbortSignal, // Allows us to cancel / requests made in quick succession
) {
  const requestSearchParams: SearchLocalQueryBody = {
    filter_eap: params.isEap,
    filter_specialities: params.specialities,
    filter_approaches: params.approaches,
    filter_formats: params.formats,
    filter_locations: params.locations,
    filter_languages: params.languages,
    filter_genders: params.genders,
    filter_identities: params.identities,
    filter_insurances: params.insurances,
    filter_price_range: params.price
      ? [params.price.min, params.price.max]
      : undefined,
    filter_keywords: params.keywords,
  };
  return await render<SearchLocalResponse>(
    fetch(`${process.env.NEXT_PUBLIC_APP_API_URL}/directory/local_search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestSearchParams),
      signal,
    }),
  );
}

function mapSearchResponse(s: {
  params: SearchQueryBody;
  result: SearchResponseResult;
}): SearchResponse {
  const p = s.params;

  // Next does not support undefined keys in static props - omit the key
  // when the value is undefined.
  //
  // See https://github.com/vercel/next.js/discussions/11209
  const filters: SearchParams["filters"] = {};
  if (p.filter_specialities) filters.specialities = p.filter_specialities;
  if (p.filter_approaches) filters.approaches = p.filter_approaches;
  if (p.filter_formats) filters.formats = p.filter_formats;
  if (p.filter_locations) filters.locations = p.filter_locations;
  if (p.filter_languages) filters.languages = p.filter_languages;
  if (p.filter_identities) filters.identities = p.filter_identities;
  if (p.filter_insurances) filters.insurances = p.filter_insurances;
  if (p.filter_genders) filters.genders = p.filter_genders;
  if (p.filter_eap) filters.isEap = p.filter_eap;
  if (p.filter_keywords) filters.keywords = p.filter_keywords;
  if (p.filter_zipcodes) filters.zipcodes = p.filter_zipcodes;
  if (p.filter_price_range)
    filters.price = {
      min: p.filter_price_range[0],
      max: p.filter_price_range[1],
    };

  const params: SearchParams = { filters };
  if (s.params.seed) params.seed = s.params.seed;
  if (s.params.page) params.page = s.params.page;
  if (s.params.page_size) params.page_size = s.params.page_size;
  if (s.params.spotlight_profile_ids)
    params.spotlight_profile_ids = s.params.spotlight_profile_ids;

  return {
    params,
    result: s.result,
  };
}

function generateSearchParams(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: { [key: string]: string | string[] | undefined },
  availableFilters: SearchFiltersOptions,
  contextLocale?: string,
) {
  const searchParams: SearchParams = {
    filters: {
      languages: [],
    },
  };

  Object.keys(query).forEach((key) => {
    const value = query[key];
    const strValue = String(value);
    const values = strValue.split(",");

    switch (key) {
      case "spotlight_profile_ids": {
        searchParams.spotlight_profile_ids = values;
        break;
      }
      case "specialities": {
        const validSpecialities = availableFilters.specialities.all.map(
          (option) => option.search_filter_id,
        );
        searchParams.filters!.specialities = values.filter((val) =>
          validSpecialities.includes(val),
        );
        break;
      }
      case "approaches": {
        const validApproaches = availableFilters.approaches.all.map(
          (option) => option.search_filter_id,
        );
        searchParams.filters!.approaches = values.filter((val) =>
          validApproaches.includes(val),
        );
        break;
      }
      case "formats": {
        const validFormats = availableFilters.formats.all.map(
          (option) => option.search_filter_id,
        );
        searchParams.filters!.formats = values.filter((val) =>
          validFormats.includes(val),
        );
        break;
      }
      case "locations": {
        const validLocations = availableFilters.locations.all.map(
          (option) => option.search_filter_id,
        );
        searchParams.filters!.locations = values.filter((val) =>
          validLocations.includes(val),
        );
        break;
      }
      case "languages": {
        const validLanguages = availableFilters.languages.all.map(
          (option) => option.search_filter_id,
        );
        searchParams.filters!.languages = values.filter((val) =>
          validLanguages.includes(val),
        );
        break;
      }
      case "identities": {
        const validIdentities = availableFilters.identities.all.map(
          (option) => option.search_filter_id,
        );
        searchParams.filters!.identities = values.filter((val) =>
          validIdentities.includes(val),
        );
        break;
      }
      case "genders": {
        const validGenders = availableFilters.genders.all.map(
          (option) => option.search_filter_id,
        );
        searchParams.filters!.genders = values.filter((val) =>
          validGenders.includes(val),
        );
        break;
      }
      case "insurances": {
        const validInsurances = availableFilters.insurances.all.map(
          (option) => option.search_filter_id,
        );
        searchParams.filters!.insurances = values.filter((val) =>
          validInsurances.includes(val),
        );
        break;
      }
      case "price": {
        const [min, max] = strValue.split(",").map(Number);
        if (!isNaN(min) && !isNaN(max)) {
          searchParams.filters!.price = { min, max };
        }
        break;
      }
      default:
        // Not logging to sentry here, as this would be triggered by someone messing with a query param,
        // and we don't want to spend our budget on such things
        // eslint-disable-next-line no-console
        console.error(`Unknown query parameter: ${key}`);
        break;
    }
  });

  if (query.seed) {
    searchParams.seed = String(query.seed);
  }

  if (query.page) {
    searchParams.page = parseInt(String(query.page));
  }

  if (query.page_size) {
    searchParams.page_size = parseInt(String(query.page_size));
  }

  // Set default language if not present
  if (
    searchParams.filters!.languages &&
    searchParams.filters!.languages.length === 0
  ) {
    searchParams.filters!.languages = contextLocale === "de" ? ["de"] : [];
  }

  return searchParams;
}

export {
  generateSearchParams,
  search,
  searchOptions,
  searchForLocalTherapists,
};
export type {
  SearchFiltersOptions,
  SearchOption,
  SearchParams,
  SearchFilterParams,
  ProfileCompact,
  SearchResponse,
  SearchResponseResult,
  SearchSelect,
  SearchSelectWithSlug,
};
