/*
 * 2023-11-23
 *
 * ResponsiveMenuBar show a dopdown menu on desktop and a fullscreen dialog
 * on mobile.
 *
 * Unfortunataly, both Radix Menubar and Popover do not support this
 * use case. The same API as been re-implemented to support this UX.
 */
import { QUERIES } from "@/design-system/breakpoints";
import * as Floating from "@floating-ui/react-dom";
import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import styled from "styled-components";

type ResponsiveMenuBarContextValue = {
  floating: Floating.UseFloatingReturn;
};
const ResponsiveMenuBarContext =
  React.createContext<ResponsiveMenuBarContextValue | null>(null);

type PropsWithClassName = { className?: string };
type PropsWithChildrenAndStyle<T = unknown> = React.PropsWithChildren<
  PropsWithClassName & T
>;

type MenuProps = PropsWithChildrenAndStyle;

function Menu(props: MenuProps) {
  return (
    <ResponsiveMenuBarContext.Provider
      value={{
        floating: Floating.useFloating({
          strategy: "fixed",
          placement: "bottom-start",
          whileElementsMounted: Floating.autoUpdate,
          middleware: [
            Floating.offset({
              mainAxis: 5,
              alignmentAxis: 0,
            }),
            Floating.shift({
              mainAxis: true,
              crossAxis: false,
            }),
          ],
        }),
      }}
    >
      <Dialog.Root {...props} />
    </ResponsiveMenuBarContext.Provider>
  );
}

type TriggerProps = PropsWithChildrenAndStyle;

function Trigger(props: TriggerProps) {
  const { floating } = checkContextNotNull(
    React.useContext(ResponsiveMenuBarContext),
  );

  return (
    <Dialog.Trigger
      ref={floating.refs.setReference}
      style={{ pointerEvents: "auto" }}
      asChild
      {...props}
    />
  );
}

type ContentProps = PropsWithChildrenAndStyle;

function Content(props: ContentProps) {
  const { floating } = checkContextNotNull(
    React.useContext(ResponsiveMenuBarContext),
  );

  return (
    <DialogContent
      {...props}
      ref={floating.refs.setFloating}
      style={floating.floatingStyles}
    />
  );
}

type PortalProps = React.PropsWithChildren;

function Portal(props: PortalProps) {
  const [container, setContainer] = React.useState<HTMLElement>();

  React.useEffect(() => {
    // effect are only executed in the browser
    setContainer(document.getElementById("radix-themed-portal") ?? undefined);
  }, []);

  return <Dialog.Portal container={container}>{props.children}</Dialog.Portal>;
}

function CloseButton(props: React.PropsWithChildren & { asChild?: boolean }) {
  return <Dialog.Close asChild={props.asChild}>{props.children}</Dialog.Close>;
}

function checkContextNotNull(context: ResponsiveMenuBarContextValue | null) {
  if (!context) {
    throw new Error("Menu must wrap the ResponsiveMenuBar components");
  }
  return context;
}

const DialogContent = styled(Dialog.Content)`
  @media ${QUERIES.mobile} {
    /* override use floating transformation, and show full screen */
    transform: none !important;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    min-height: revert;
    max-height: 100%;
    width: 100vw;
    border-radius: 0;
  }

  background-color: white;
`;

const ResponsiveMenuBar = {
  CloseButton,
  Content,
  Menu,
  Portal,
  Trigger,
};
export default ResponsiveMenuBar;
export { ResponsiveMenuBar };
