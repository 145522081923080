"use client";
import { ComponentProps } from "react";
import { Link, pathnames } from "@/navigation";

// This is a wrapper around the Link component that forces it "uses client".
// This seems to make it composable with Button when used outside the client,
// , though not yet sure why that is necessary.
export default function ClientSideLink<
  Pathname extends keyof typeof pathnames,
>({ href, ...rest }: ComponentProps<typeof Link<Pathname>>) {
  return <Link href={href} {...rest} />;
}
