import { useTranslations } from "next-intl";
import { FilterMenuProps } from "@components/Search/Filter/FilterMenuProps";
import { FilterMenu } from "./FilterMenu";
import { Text } from "@/design-system/components/Text";
import { FilterCheckboxList } from "@components/Search/Filter/FilterCheckboxList";
import { Flex } from "@radix-ui/themes";
import React from "react";

function FilterMenuFormat({ options, filters, setFilters }: FilterMenuProps) {
  const t = useTranslations("Search.filters");

  const totalSelected = filters?.formats?.length || 0;

  return (
    <FilterMenu
      buttonLabel={t("format.label")}
      contentLabel={t("format.label")}
      totalSelected={totalSelected}
      fixedWidth={true}
    >
      <FormatFilter
        setFilters={setFilters}
        filters={filters}
        options={options}
      />
    </FilterMenu>
  );
}
function FormatFilter({ options, filters, setFilters }: FilterMenuProps) {
  const t = useTranslations("Search.filters");
  return (
    <Flex direction={"column"} gap={"3"}>
      <Text tiny light>
        {t("format.format_name")}
      </Text>
      <FilterCheckboxList
        name="format"
        type={"wrapped_row"}
        options={options.formats.all}
        selected={filters?.formats}
        onSelectedChange={(selected) =>
          setFilters({
            ...filters,
            formats: selected,
          })
        }
      />
    </Flex>
  );
}

export { FilterMenuFormat, FormatFilter };
