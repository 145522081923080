import React from "react";
import { RemoveScroll } from "react-remove-scroll";
import { useMediaQuery } from "react-responsive";
import { QUERIES } from "@/design-system/breakpoints";

function DisableAncestorScrollOnMobile({ children }: React.PropsWithChildren) {
  const shouldDisableAncestorScroll = useMediaQuery({ query: QUERIES.mobile });
  return (
    <RemoveScroll enabled={shouldDisableAncestorScroll}>
      {children}
    </RemoveScroll>
  );
}

export { DisableAncestorScrollOnMobile };
