import { useFormatter, useTranslations } from "next-intl";
import { FilterMenuProps } from "@components/Search/Filter/FilterMenuProps";
import { FilterMenu } from "./FilterMenu";
import { Box, Flex, Separator } from "@radix-ui/themes";
import React, { useCallback, useRef } from "react";
import config from "@components/Search/config.search";
import * as Slider from "@radix-ui/react-slider";
import styles from "./FilterMenuCost.module.scss";
import { FilterCheckboxList } from "./FilterCheckboxList";
import { Text } from "@/design-system/components/Text";
import { SearchParams } from "@/api/api.directory.search";

function FilterMenuCost({
  options,
  filters,
  setFilters,
  hasPriceFilterChanged,
}: FilterMenuProps) {
  const t = useTranslations("Search.filters.cost");
  const totalSelected =
    (hasPriceFilterChanged ? 1 : 0) +
    (filters?.insurances?.length || 0) +
    (filters?.isEap ? 1 : 0);

  return (
    <FilterMenu
      buttonLabel={t("label")}
      contentLabel={t("label")}
      totalSelected={totalSelected}
      fixedWidth={true}
    >
      <PaymentsFilter
        options={options}
        filters={filters}
        setFilters={setFilters}
      />
    </FilterMenu>
  );
}

function PaymentsFilter({ options, filters, setFilters }: FilterMenuProps) {
  const t = useTranslations("Search.filters.cost");
  const EAP_ITEM = {
    search_filter_id: "payment_option_EAP",
    name: t("payment_options.eap"),
    analytics_id: "payment_option_EAP",
  };

  return (
    <Flex direction={"column"} gap={"3"}>
      <CostFilter filters={filters} setFilters={setFilters} />

      <Separator size="4" my={"3"} />

      <Text tiny light>
        {t("payment_options.label")}
      </Text>

      <FilterCheckboxList
        name="insurances"
        type={"wrapped_row"}
        options={[...options.insurances.all, EAP_ITEM]}
        selected={[
          ...(filters?.insurances || []),
          filters?.isEap ? "payment_option_EAP" : undefined,
        ].filter((item): item is string => !!item)}
        onSelectedChange={(selected) =>
          setFilters({
            ...filters,
            insurances: selected.filter((e) => e !== "payment_option_EAP"),
            isEap: selected.includes("payment_option_EAP") ? true : undefined,
          })
        }
      />
    </Flex>
  );
}

function StandalonePaymentsFilter({
  options,
  filters,
  setFilters,
}: FilterMenuProps) {
  return (
    <PaymentsFilter
      options={options}
      filters={filters}
      setFilters={setFilters}
    />
  );
}

function CostFilter({
  filters,
  setFilters,
}: {
  filters: SearchParams["filters"];
  setFilters: (f: SearchParams["filters"]) => void;
}) {
  const onChangeValues = useCallback(
    (values: [number, number]) => {
      setFilters({
        ...filters,
        price: {
          min: values[0],
          max: values[1],
        },
      });
    },
    [setFilters, filters],
  );

  const actualMin = filters?.price?.min;
  const actualMax = filters?.price?.max;
  const isMinProvided = typeof actualMin === "number";
  const isMaxProvided = typeof actualMax === "number";
  const values = [
    isMinProvided ? actualMin : config.price_min,
    isMaxProvided ? actualMax : config.price_max,
  ] as [number, number];

  return (
    <Box pb={"5"}>
      <PriceSlider values={values} setValues={onChangeValues} />
    </Box>
  );
}

function PriceSlider({
  values,
  setValues,
}: {
  values: [number, number];
  setValues: (values: [number, number]) => void;
}) {
  const format = useFormatter();

  const formatPrice = (value: number) => {
    return format.number(value, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const priceMinLabelRef = useRef<HTMLDivElement>(null);
  const priceMaxLabelRef = useRef<HTMLDivElement>(null);

  const handleValueChange = (values: [number, number]) => {
    setValues(values);
    
    const minValue = values[0];
    const maxValue = values[1];

    // Prevent overlap of min/max price labels
    if (priceMinLabelRef.current && priceMaxLabelRef.current) {
      if (maxValue - minValue === config.price_step) {
        if (minValue === config.price_min) {
          // Keep min label position on the left and adjust max label on the right
          priceMaxLabelRef.current.style.left = "calc(50% - 5px)";
        } else if (maxValue === config.price_max) {
          // Keep max label position on the right and adjust min label on the left
          priceMinLabelRef.current.style.left = "calc(50% - 45px)";
        } else {
          // Adjust both min and max positions so they are centered
          priceMinLabelRef.current.style.left = "calc(50% - 35px)";
          priceMaxLabelRef.current.style.left = "calc(50% - 15px)";
        }
      } else {
        // Reset labels positions when they are not close to each other
        priceMinLabelRef.current.style.left = "calc(50% - 25px)";
        priceMaxLabelRef.current.style.left = "calc(50% - 25px)";
      }
    }
  };

  return (
    <div className={styles.sliderContainer}>
      <Slider.Root
        className={styles.sliderRoot}
        id="input_price"
        min={config.price_min}
        max={config.price_max}
        defaultValue={values}
        step={config.price_step}
        minStepsBetweenThumbs={1}
        onValueChange={handleValueChange}
        name="price_range"
      >
        <Slider.Track className={styles.sliderTrack}>
          <Slider.Range className={styles.sliderRange} />
        </Slider.Track>
        <Slider.Thumb className={styles.sliderThumb}>
          <div ref={priceMinLabelRef} className={styles.priceMinLabel}>
            {formatPrice(values[0])}
          </div>
        </Slider.Thumb>
        <Slider.Thumb className={styles.sliderThumb}>
          <div ref={priceMaxLabelRef} className={styles.priceMaxLabel}>
            {formatPrice(values[1])}
          </div>
        </Slider.Thumb>
      </Slider.Root>
    </div>
  );
}

export { FilterMenuCost, StandalonePaymentsFilter };
