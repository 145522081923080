"use client";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import React from "react";
import { useTranslations } from "next-intl";
import { Flex } from "@radix-ui/themes";

function ListenOnSpotifyCTA() {
  const t = useTranslations("Podcast");
  return (
    <Flex direction={"column"} align={"center"}>
      <ButtonV2
        variant={"outline"}
        color={"mint"}
        size={"3"}
        onClick={() => {
          window.open(
            "https://open.spotify.com/show/6PsVdBM8ed4z6U7UCPSGMx",
            "_blank",
          );
          return true;
        }}
      >
        {t("cta")}
      </ButtonV2>
    </Flex>
  );
}

export { ListenOnSpotifyCTA };
