import { IconButton } from "@/design-system/components/IconButton";
import ResponsiveMenuBar from "@/design-system/components/ResponsiveMenuBar";
import { MobileView } from "@/design-system/responsive-helpers/MobileView";
import { Box, Flex, ScrollArea, Separator } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import React from "react";
import { NonMobileView } from "@/design-system/responsive-helpers/NonMobileView";
import { DisableAncestorScrollOnMobile } from "@/design-system/responsive-helpers/DisableAncestorScrollOnMobile";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import FilterButton from "@components/Search/Filter/FilterButton";
import styles from "@components/Search/Filter/Filter.module.scss";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { CaretUpIcon, Cross2Icon } from "@radix-ui/react-icons";

type FilterMenuProps = {
  buttonLabel: string;
  contentLabel: string;
  totalSelected: number;
  children: React.ReactNode;
  fixedWidth?: boolean;
};

function FilterMenu({
  buttonLabel,
  contentLabel,
  totalSelected,
  children,
  fixedWidth,
}: FilterMenuProps) {
  const t = useTranslations();
  return (
    <ResponsiveMenuBar.Menu>
      <ResponsiveMenuBar.Trigger>
        <FilterButton
          label={buttonLabel}
          appliedCount={totalSelected}
          className={styles.filterTrigger}
        />
      </ResponsiveMenuBar.Trigger>
      <ResponsiveMenuBar.Portal>
        <DisableAncestorScrollOnMobile>
          <ResponsiveMenuBar.Content
            className={`${styles.filterMenubarContent} ${fixedWidth ? styles.fixedWidth : ""}`}
          >
            <Flex direction={"row"} justify={"between"} mb={"5"} px={"5"}>
              <HeadingV2 as={"h3"} textStyle={"Headline S"}>
                {contentLabel}
              </HeadingV2>
              <ResponsiveMenuBar.CloseButton asChild>
                <IconButton tertiary>
                  <MobileView>
                    <Cross2Icon width={20} height={20} />
                  </MobileView>
                  <NonMobileView>
                    <CaretUpIcon width={20} height={20} />
                  </NonMobileView>
                </IconButton>
              </ResponsiveMenuBar.CloseButton>
            </Flex>
            <Box px={"5"}>
              <Separator size="4" />
            </Box>

            <ScrollArea className={styles.filterScrollArea}>
              <Box py="5" px={"5"}>
                {children}
              </Box>
            </ScrollArea>

            <Flex direction={"column"} gap={"4"} px={"5"}>
              <Separator size="4" />
              <ResponsiveMenuBar.CloseButton asChild>
                <ButtonV2
                  variant={"solid"}
                  size={"4"}
                  style={{ width: "100%" }}
                  color={"mint"}
                  radius={"full"}
                >
                  {t("Navigation.close")}
                </ButtonV2>
              </ResponsiveMenuBar.CloseButton>
            </Flex>
          </ResponsiveMenuBar.Content>
        </DisableAncestorScrollOnMobile>
      </ResponsiveMenuBar.Portal>
    </ResponsiveMenuBar.Menu>
  );
}
export { FilterMenu };
