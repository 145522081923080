import React from "react";
import styled from "styled-components";
import { FilterMenuProps } from "@components/Search/Filter/FilterMenuProps";
import { useTranslations } from "next-intl";
import { QUERIES } from "@/design-system/breakpoints";

function FilterSearch({ filters, setFilters }: FilterMenuProps) {
  const t = useTranslations("Search");
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <FilterSearchContainer>
      <Input
        ref={ref}
        name="search_by_name"
        placeholder={t("search_filter_placeholder")}
        defaultValue={filters?.keywords}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            setFilters({
              ...filters,
              keywords: ref.current?.value || undefined,
            });
          }
        }}
      />
    </FilterSearchContainer>
  );
}

const FilterSearchContainer = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 200px;
`;

const Input = styled.input`
  width: 100%;
  padding: 6px 16px 8px 16px;
  font-size: var(--font-size-16);
  border: 1px solid;
  border-radius: 22px;
  border-color: var(--grey-hsl-0-0-83);
  background-color: white;
  line-height: 0.5;

  outline: none;

  @media ${QUERIES.mobile} {
    width: revert;
  }
`;

export { FilterSearch };
