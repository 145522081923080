import React from "react";
import styled from "styled-components";

type PaginationProps = {
  total: number;
  size?: number;
  activePage: number;
  setActivePage: (n: number) => void;
  anchorPageTop?: string;
};

function Pagination({
  total,
  setActivePage,
  activePage,
  size,
  anchorPageTop,
}: PaginationProps) {
  if (total === 1) {
    // Don't show Pagination unless there are more than 1 pages
    return null;
  }

  const pages = [];

  const selectPage = (e: React.MouseEvent<HTMLElement>, page: number) => {
    setActivePage(page);
  };

  const pagesLinksTotal = size || 10;
  const start = Math.max(0, activePage + 1 - Math.floor(pagesLinksTotal / 2));
  const end = Math.min(start + pagesLinksTotal, total);
  for (let page = start; page < end; page++) {
    pages.push(
      <Page
        key={page}
        onClick={(e) => {
          selectPage(e, page);
        }}
        href={`#${anchorPageTop ?? ""}`}
        $isActive={page === activePage}
      >
        {page + 1}
      </Page>,
    );
  }
  return <Container>{pages}</Container>;
}

const Container = styled.ul`
  padding: 0;
`;

type PageProps = { $isActive: boolean };
const Page = styled.a<PageProps>`
  color: var(--color-text);
  background-color: ${(props) => (props.$isActive ? "var(--green-low)" : null)};
  float: left;
  padding: 8px 16px;
  text-decoration: none;

  :hover {
    background-color: var(--green-low-over);
  }
`;

export { Pagination };
