"use client";
import { Box, Flex, Grid, Separator } from "@radix-ui/themes";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import React from "react";
import NewTagWithSparkles from "@/design-system/components/tags/new-tag-with-sparkles/NewTagWithSparkles";
import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import { DesktopView } from "@/design-system/responsive-helpers/DesktopView";

interface PillarContent {
  title: string;
  description: string;
  cta: React.ReactNode;
  hasNewTag?: boolean;
}

function PillarsSection({ pillars }: { pillars: PillarContent[] }) {
  return (
    <DesktopView>
      <LayoutContainerV2>
        <Grid columns="3" gapX="9" gapY={"5"} width="auto">
          {/* Render all separators first */}
          {pillars.map((_, index) => (
            <Separator
              key={`separator-${index}`}
              orientation={"horizontal"}
              style={{ backgroundColor: "var(--colorV2-dark-green)" }}
              size={"4"}
            />
          ))}

          {/* Render all headings (with optional tags) */}
          {pillars.map((pillar, index) => (
            <Flex direction={"row"} gap={"3"} key={`heading-${index}`}>
              <HeadingV2 textStyle={"Headline L"} as={"h2"} align={"left"}>
                {pillar.title}
              </HeadingV2>
              {pillar.hasNewTag && <NewTagWithSparkles />}
            </Flex>
          ))}

          {/* Render all descriptions */}
          {pillars.map((pillar, index) => (
            <TextV2
              textStyle={"Body M"}
              align={"left"}
              key={`description-${index}`}
            >
              {pillar.description}
            </TextV2>
          ))}

          {/* Render all CTAs */}
          {pillars.map((pillar, index) => (
            <PillarCTA key={`cta-${index}`}>{pillar.cta}</PillarCTA>
          ))}
        </Grid>
      </LayoutContainerV2>
    </DesktopView>
  );
}

function PillarCTA({ children }: { children: React.ReactNode }) {
  return (
    <Box pt={"4"} pb={"8"} maxWidth={"100%"}>
      <Box maxWidth={"100%"} asChild>
        <ButtonV2 variant={"outline"} size={"3"} asChild>
          {children}
        </ButtonV2>
      </Box>
    </Box>
  );
}

export { PillarsSection };
