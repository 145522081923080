"use client";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import React from "react";
import { useTranslations } from "next-intl";
import { Flex } from "@radix-ui/themes";

function ReadBlogArticleCTA({ href }: { href: string }) {
  const t = useTranslations("Blog");
  return (
    <Flex direction={"column"} align={"start"}>
      <ButtonV2
        variant={"ghost"}
        color={"mint"}
        size={"3"}
        onClick={() => {
          window.open(href, "_blank");
          return true;
        }}
      >
        {t("read")}
      </ButtonV2>
    </Flex>
  );
}

export { ReadBlogArticleCTA };
