"use client";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import React from "react";
import { useTranslations } from "next-intl";
import { Flex, Link } from "@radix-ui/themes";

function BrowseTheBlogCTA() {
  const t = useTranslations("Blog");
  return (
    <Flex direction={"column"} align={"center"}>
      <ButtonV2 variant={"outline"} size={"3"} asChild>
        <Link href="/blog">{t("browse")}</Link>
      </ButtonV2>
    </Flex>
  );
}

export { BrowseTheBlogCTA };
