"use client";
import { useTranslations } from "next-intl";
import React from "react";
import { Box, Flex } from "@radix-ui/themes";
import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import styles from "./ClientTestimonials.module.scss";
import { TextV2 } from "@/design-system/components/text/TextV2";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPaginationDots,
} from "@/design-system/components/Carousel/Carousel";

function ClientTestimonials() {
  const testimonialKeys = [
    "testimonial_1",
    "testimonial_2",
    "testimonial_3",
    "testimonial_4",
  ] as const;
  return (
    <Box py={{ initial: "8", sm: "9" }}>
      <Carousel
        className={styles.carousel}
        loop={true}
        autoPlay={true}
        stopOnHover={true}
      >
        <CarouselContent className={styles.carouselContent}>
          {Array.from({ length: 4 }).map((_, index) => (
            <CarouselItem key={index + 1} className={styles.carouselItem}>
              <ClientTestimonial testimonialKey={testimonialKeys[index]} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPaginationDots
          className={styles.carouselPaginationDots}
          isGreenLayout
        />
      </Carousel>
    </Box>
  );
}

function ClientTestimonial({
  testimonialKey,
}: {
  testimonialKey:
    | "testimonial_1"
    | "testimonial_2"
    | "testimonial_3"
    | "testimonial_4";
}) {
  const t = useTranslations(`ClientTestimonials.${testimonialKey}`);
  return (
    <LayoutContainerV2
      size={"3"}
      style={{
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Flex
        direction={"column"}
        gap={{ initial: "7", sm: "9" }}
        justify={"center"}
      >
        <TextV2 textStyle={"Headline XL"}>
          {t.rich("quote", {
            green: (chunks) => (
              <span style={{ color: "var(--colorV2-dark-green" }}>
                {chunks}
              </span>
            ),
          })}
        </TextV2>
        <TextV2 textStyle={"Headline S"}>{t("source")}</TextV2>
      </Flex>
    </LayoutContainerV2>
  );
}

export { ClientTestimonials };
