import React, { PropsWithChildren, ReactNode } from "react";
import { Flex } from "@radix-ui/themes";
import { DesktopView } from "@/design-system/responsive-helpers/DesktopView";
import { MarginProps } from "@radix-ui/themes/src/props";

type MediaTextPaneProps = {
  media: ReactNode;
  text: ReactNode;
  bottom?: ReactNode;
} & PropsWithChildren;

// This mainly exists because lots of our layouts have this 60/40 split, and I want to minimize duplication
function MediaTextPane({
  media,
  text,
  bottom,
  ...props
}: MediaTextPaneProps & MarginProps) {
  return (
    <Flex direction={"column"} align={"center"} gap={"7"} {...props}>
      <Flex direction={"row"} gap={"7"} width={"100%"}>
        <DesktopView style={{ flexGrow: "1" }}>
          <Flex align={"center"} justify={"center"}>
            {media}
          </Flex>
        </DesktopView>
        <Flex
          direction={"column"}
          gap={"5"}
          align={{ initial: "center", md: "start" }}
          width={{ initial: "100%", md: "60%" }}
          justify={"center"}
        >
          {text}
        </Flex>
      </Flex>
      {bottom}
    </Flex>
  );
}
export { MediaTextPane };
