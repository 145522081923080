"use client"; // we should aim to remove this as part of the directory work
import { SkeletonTheme } from "react-loading-skeleton";
import { trackSearchImpression } from "@/api/api.stats";
import React, { Fragment } from "react";
import { Pagination } from "./Pagination";
import { Flex } from "@radix-ui/themes";
import {
  DirectoryCard,
  DirectoryCardSkeleton,
} from "@components/Search/List/DirectoryCard";
import { DirectoryResultsContainer } from "@components/Directory/results/container/DirectoryResultsContainer";
import { useUserSession } from "@/contexts/UserSession";
import { SearchResponseResult, ProfileCompact } from "@/api/api.directory.search";

type ListProps = {
  isSearching: boolean;
  searchResponse: SearchResponseResult | null;
  setActiveResultPage: (p: number) => void;
  anchorPageTop?: string;
  eapFilterIsOn: boolean;
  listInserts: ListInsert[];
};

type PageCondition = {
  type: "before" | "after" | "on" | "before_and_on" | "after_and_on" | "after_and_on_except";
  page: number;
  pageException?: number;
};
type ListInsert = {
  pageCondition?: PageCondition;
  position: number;
  element: JSX.Element;
  fullWidth?: boolean;
};

function List({
  searchResponse,
  isSearching,
  setActiveResultPage,
  anchorPageTop,
  eapFilterIsOn,
  listInserts,
}: ListProps) {
  // We should actually do this on the backend, but this would involve
  // proxying the search requests through an api route where we have the token.
  // Maybe later. until then, we might  temporarily show prices to EAP people without the EAP filter on
  const { session } = useUserSession();
  const activeInEapProgram =
    session && session.user && !session.user.cannot_book_eap_session_reason;

  return (
    <SkeletonTheme
      baseColor="#E0DEDB"
      highlightColor="var(--colorV2-grey-light"
    >
      <Flex
        direction={"column"}
        gap={"9"}
        style={{ containerType: "inline-size" }}
        flexGrow={"1"}
      >
        {isSearching && <ProfileCardSkeletons />}
        {!isSearching && searchResponse && (
          <SearchResult
            searchResponse={searchResponse}
            hideEAPPrices={activeInEapProgram ?? eapFilterIsOn}
            listInserts={listInserts}
          />
        )}
        {searchResponse && (
          <Pagination
            anchorPageTop={anchorPageTop}
            total={searchResponse.total_pages}
            activePage={searchResponse.page}
            setActivePage={setActiveResultPage}
          />
        )}
      </Flex>
    </SkeletonTheme>
  );
}

type SearchResultProps = {
  searchResponse: SearchResponseResult;
  hideEAPPrices: boolean;
  listInserts: ListInsert[];
};

function SearchResult({
  searchResponse,
  hideEAPPrices,
  listInserts,
}: SearchResultProps) {
  const {
    spotlights,
    page: currentPage,
    ranker_version,
    total_matches,
  } = searchResponse;
  // Spotlight will be inserted at the first position and must be removed from the search result
  const profiles = searchResponse.profiles.filter(
    (p) => spotlights?.find((s) => s.id === p.id) === undefined,
  );

  const defaultTrackingData = [
    `lpi=${currentPage}`,
    `lrv=${ranker_version}`,
    `lts=${total_matches}`,
  ];

  const profilesUris = profiles.map((profile, position) => {
    const uri = `/find-a-therapist${profile.partial_uri}`;
    // rank starts at posisiton 1 in analytics
    const rank = position + 1;
    const trackingData = [...defaultTrackingData, `lr=${rank}`];
    return `${uri}?${trackingData.join("&")}`;
  });

  let cards;
  let uris: string[] = [];
  let rankData: string[] = [];
  if (spotlights) {
    // Insert spotlight at the first position
    cards = [...spotlights, ...profiles];

    // Add uris and ranks for spotlights
    for (let i = 1; i <= spotlights.length; i++) {
      const profileUri = `/find-a-therapist${spotlights[i - 1].partial_uri}`;
      const spotlighTrackingData = [
        ...defaultTrackingData,
        "lr=spotlight_" + i.toString(),
      ];
      const spotlightUri = `${profileUri}?${spotlighTrackingData.join("&")}`;
      uris.push(spotlightUri);
      rankData.push("spotlight_" + i.toString());
    }
  } else {
    cards = profiles;
  }

  uris = uris.concat(profilesUris);
  rankData = rankData.concat(
    profiles.map((_, position) => (position + 1).toString()),
  );

  const filteredInserts = listInserts.filter((insert) => {
    if (!insert.pageCondition) {
      return true;
    }

    const { type, page, pageException } = insert.pageCondition;
    switch (type) {
      case "before":
        return currentPage < page;
      case "after":
        return currentPage > page;
      case "on":
        return currentPage === page;
      case "before_and_on":
        return currentPage <= page;
      case "after_and_on":
        return currentPage >= page;
      case "after_and_on_except":
        return currentPage >= page && currentPage !== pageException;
      default:
        return false;
    }
  });

  return (
    <ProfilesCardsList
      cards={cards}
      uris={uris}
      rankData={rankData}
      hideEAPPrices={hideEAPPrices}
      inserts={filteredInserts}
    />
  );
}

type ProfilesCardsListProps = {
  cards: ProfileCompact[];
  uris: string[];
  rankData: string[];
  hideEAPPrices: boolean;
  inserts?: {
    position: number;
    page?: number;
    element: JSX.Element;
    fullWidth?: boolean;
  }[];
};

function ProfilesCardsList({
  cards,
  uris,
  rankData,
  hideEAPPrices,
  inserts = [],
}: ProfilesCardsListProps) {
  // Construct the array of items including cards and potential inserts
  const items = cards.map((profile, position) => ({
    type: "card",
    content: (
      <Fragment key={`fragment-card-${position}`}>
        <DirectoryCard
          key={profile.id}
          uri={uris[position]}
          profile={profile}
          showPriceState={hideEAPPrices ? "non-eap-only" : "all"}
          trackImpression={() =>
            trackSearchImpression(profile.user_id, rankData[position])
          }
        />
      </Fragment>
    ),
  }));

  // Insert the additional components at specified positions
  inserts.forEach((insert) => {
    if (insert.position <= items.length) {
      items.splice(insert.position, 0, {
        type: "insert",
        content: (
          <div
            key={`fragment-insert-${insert.position}`}
            style={
              insert.fullWidth ? { gridColumn: "1 / -1", width: "100%" } : {}
            }
          >
            {insert.element}
          </div>
        ),
      });
    }
  });

  return (
    <DirectoryResultsContainer>
      {items.map((item) => item.content)}
    </DirectoryResultsContainer>
  );
}

function ProfileCardSkeletons() {
  return (
    <DirectoryResultsContainer>
      {Array.from({ length: 24 }).map((_, index) => (
        <DirectoryCardSkeleton key={index} />
      ))}
    </DirectoryResultsContainer>
  );
}
export { List };
export type { ListInsert };
