import React, { useState, useEffect } from "react";
import styles from "./NewTagWithSparkles.module.scss";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { Box } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import sparkleGreen from "/public/sparkle_green.png";

const NewTagWithSparkles = () => {
  const t = useTranslations("Common");

  // Define the initial state for particles, including their fixed positions and delays
  const initialParticles = [
    { top: "-6px", left: "-8px", delay: "0.6s" },
    { top: "-11px", left: "23px", delay: "1.8s" },
    { top: "-9px", left: "45px", delay: "2.9s" },
    { top: "39px", left: "13px", delay: "0.2s" },
    { top: "37px", left: "39px", delay: "1.5s" },
  ];

  // State to hold rotations; initialized with null values
  const [rotations, setRotations] = useState(
    Array(initialParticles.length).fill(null),
  );

  useEffect(() => {
    // Generate random rotations for each particle
    setRotations((currentRotations) =>
      currentRotations.map(() => Math.random() * 360),
    );
  }, []);

  return (
    <div className={styles.container}>
      <Box className={styles.tag} py={"2"} px={"3"} asChild>
        <TextV2 textStyle={"Label XS"}>{t("new")}</TextV2>
      </Box>
      {initialParticles.map((particle, index) => (
        <div
          key={index}
          className={styles.particle}
          style={{
            top: particle.top,
            left: particle.left,
            animationDelay: particle.delay,
            transform: `rotate(${rotations[index]}deg)`, // Apply the rotation from state
            backgroundImage: `url(${sparkleGreen.src})`,
          }}
        />
      ))}
    </div>
  );
};

export default NewTagWithSparkles;
