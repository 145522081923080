import { useTranslations } from "next-intl";
import { FilterCombobox } from "./FilterCombobox";
import { FilterMenuProps } from "@components/Search/Filter/FilterMenuProps";
import { FilterMenu } from "./FilterMenu";

function FilterMenuLanguage({ options, filters, setFilters }: FilterMenuProps) {
  const t = useTranslations("Search.filters.language");
  const totalSelected = filters?.languages?.length || 0;

  return (
    <FilterMenu
      buttonLabel={t("label")}
      contentLabel={t("label")}
      totalSelected={totalSelected}
      fixedWidth={true}
    >
      <LanguageFilter
        setFilters={setFilters}
        filters={filters}
        options={options}
      />
    </FilterMenu>
  );
}

function LanguageFilter({ options, filters, setFilters }: FilterMenuProps) {
  const t = useTranslations("Search.filters.language");
  return (
    <FilterCombobox
      id="language.label"
      ariaLabel={t("label")}
      placeholder={t("placeholder")}
      select={options.languages}
      selected={filters?.languages}
      enableShowAll={true}
      useAutofocus={true}
      onSelectedChange={(selected) =>
        setFilters({
          ...filters,
          languages: selected,
        })
      }
    />
  );
}

export { FilterMenuLanguage, LanguageFilter };
