import LabelCheckbox from "@/design-system/components/LabelCheckbox";
import { Flex } from "@radix-ui/themes";
import React from "react";

type FilterCheckboxListProps = {
  name: string;
  options: { search_filter_id: string; name: string }[];
  selected?: string[];
  type: "wrapped_row" | "column";
  onOptionAdded?: (id: string) => void;
  onOptionRemoved?: (id: string) => void;
  onSelectedChange?: (selected: string[]) => void;
};

function FilterCheckboxList(props: FilterCheckboxListProps) {
  const [selected, setSelected] = React.useState(props.selected || []);

  const toggleSelected = (id: string) => {
    const index = selected.indexOf(id);
    let selectedSnapshot;
    if (index < 0) {
      selectedSnapshot = [...selected, id];
      props.onOptionAdded?.(id);
    } else {
      selectedSnapshot = selected.filter((s) => s !== id);
      props.onOptionRemoved?.(id);
    }
    props.onSelectedChange?.(selectedSnapshot);
    setSelected(selectedSnapshot);
  };

  return (
    <Flex
      gap={"2"}
      direction={props.type === "wrapped_row" ? "row" : "column"}
      wrap={props.type === "wrapped_row" ? "wrap" : "nowrap"}
    >
      {props.options.map((option) => (
        <LabelCheckbox
          key={option.search_filter_id}
          name={props.name}
          value={option.search_filter_id}
          label={option.name}
          checked={Boolean(selected.includes(option.search_filter_id))}
          onCheckedChange={() => toggleSelected(option.search_filter_id)}
        />
      ))}
    </Flex>
  );
}

export { FilterCheckboxList };
