"use client";
import Link from "next/link";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import React from "react";
import { useTranslations } from "next-intl";
import { Flex } from "@radix-ui/themes";

function DirectoryAboutUsCTA() {
  const t = useTranslations("About");
  return (
    <Flex direction={"column"} align={{ initial: "center", sm: "start" }}>
      <ButtonV2 variant={"outline"} size={"3"} color={"gray"} asChild>
        <Link href="/our-story">{t("CTA")}</Link>
      </ButtonV2>
    </Flex>
  );
}

export { DirectoryAboutUsCTA };
