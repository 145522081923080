import { useTranslations } from "next-intl";
import { FilterCombobox } from "./FilterCombobox";
import { FilterMenuProps } from "@components/Search/Filter/FilterMenuProps";
import { FilterMenu } from "./FilterMenu";
import { Text } from "@/design-system/components/Text";
import { FilterCheckboxList } from "@components/Search/Filter/FilterCheckboxList";
import { Flex, Separator } from "@radix-ui/themes";
import React from "react";

function FilterMenuLocationAndFormat({
  options,
  filters,
  setFilters,
}: FilterMenuProps) {
  const t = useTranslations("Search.filters");

  const totalSelected =
    (filters?.formats?.length || 0) + (filters?.locations?.length || 0);

  return (
    <FilterMenu
      buttonLabel={t("location.label")}
      contentLabel={t("location.label")}
      totalSelected={totalSelected}
      fixedWidth={true}
    >
      <LocationAndFormatFilter
        setFilters={setFilters}
        filters={filters}
        options={options}
      />
    </FilterMenu>
  );
}
function LocationAndFormatFilter({
  options,
  filters,
  setFilters,
}: FilterMenuProps) {
  const t = useTranslations("Search.filters");
  return (
    <Flex direction={"column"} gap={"3"}>
      <Text tiny light>
        {t("location.format_name")}
      </Text>
      <FilterCheckboxList
        name="format"
        type={"wrapped_row"}
        options={options.formats.all}
        selected={filters?.formats}
        onSelectedChange={(selected) =>
          setFilters({
            ...filters,
            formats: selected,
          })
        }
      />
      <Separator size="4" my={"3"} />
      <Text tiny light>
        {t("location.therapist_location_name")}
      </Text>
      <FilterCombobox
        id="location.therapist_location_name"
        ariaLabel={t("location.therapist_location_name")}
        placeholder={t("location.placeholder")}
        select={{
          all: options.locations.all,
          common: options.locations.common,
        }}
        enableShowAll={true}
        selected={filters?.locations}
        useAutofocus={true}
        onSelectedChange={(selected) =>
          setFilters({
            ...filters,
            locations: selected,
          })
        }
      />
    </Flex>
  );
}

export { FilterMenuLocationAndFormat, LocationAndFormatFilter };
