"use client";
import * as Accordion from "@radix-ui/react-accordion";
import styles from "@components/Faq/FaqSection.module.scss";
import { Box, Flex } from "@radix-ui/themes";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { PlusIcon } from "@radix-ui/react-icons";
import React, { useRef } from "react";
import { FrequentlyAskedParentCategoryWithChildren, FrequentlyAskedCategoryWithFaqs, FrequentlyAskedQuestion as FrequentlyAskedQuestionType } from "@/api/api.directory.faq";

function FaqSections({
  faqSections,
}: {
  faqSections: FrequentlyAskedParentCategoryWithChildren[];
}) {
  return (
    <Accordion.Root type="multiple" className={styles.faqRoot}>
      {faqSections.map((faqSection) => (
        <Accordion.Item
          key={faqSection.slug}
          value={faqSection.slug}
          className={styles.accordionItem}
        >
          <Accordion.Header>
            <Flex>
              <Accordion.Trigger className={styles.faqCategoryTrigger}>
                <TextV2 textStyle={"Headline S"}>{faqSection.name}</TextV2>
                <PlusIcon aria-hidden className={styles.chevronIcon} />
              </Accordion.Trigger>
            </Flex>
          </Accordion.Header>
          <Accordion.Content className={styles.accordionContent}>
            <FaqSection
              faqCategories={faqSection.children}
              scrollToActiveQuestion={true}
            />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}

function FaqSection({
  faqCategories,
  scrollToActiveQuestion,
}: {
  faqCategories: FrequentlyAskedCategoryWithFaqs[];
  scrollToActiveQuestion: boolean;
}) {
  return (
    <Accordion.Root type="multiple" className={styles.faqRoot}>
      {faqCategories.map((category) => (
        <Category
          key={category.slug}
          category={category}
          scrollToActiveQuestion={scrollToActiveQuestion}
        />
      ))}
    </Accordion.Root>
  );
}

function Category({
  category,
  scrollToActiveQuestion,
}: {
  category: FrequentlyAskedCategoryWithFaqs;
  scrollToActiveQuestion: boolean;
}) {
  const lastOpenedFaqs = useRef<string[]>([]);
  return (
    <Accordion.Item value={category.slug} className={styles.accordionItem}>
      <Accordion.Header>
        <Flex>
          <Accordion.Trigger className={styles.faqCategoryTrigger}>
            <TextV2 textStyle={"Headline S"}>{category.name}</TextV2>
            <PlusIcon aria-hidden className={styles.chevronIcon} />
          </Accordion.Trigger>
        </Flex>
      </Accordion.Header>
      <Accordion.Content className={styles.accordionContent}>
        <Accordion.Root
          type="multiple"
          className={styles.faqRoot}
          onValueChange={(value) => {
            if (lastOpenedFaqs.current.length > value.length) {
              // we were closed, don't bother scrolling
              lastOpenedFaqs.current = value;
            } else {
              // smooth scroll to the last clicked FAQ
              const element = document.getElementById(
                value[value.length - 1],
              ) as HTMLElement;
              if (scrollToActiveQuestion) {
                element?.scrollIntoView({ behavior: "smooth" });
              }
              lastOpenedFaqs.current = value;
            }
          }}
        >
          {category.faqs.map((q) => (
            <FrequentlyAskedQuestion key={q.slug} faq={q} id={q.slug} />
          ))}
        </Accordion.Root>
      </Accordion.Content>
    </Accordion.Item>
  );
}

function FrequentlyAskedQuestion(props: {
  faq: FrequentlyAskedQuestionType;
  id: string;
}) {
  return (
    <Accordion.Item
      value={props.faq.slug}
      className={styles.accordionItem}
      id={props.id}
    >
      <Accordion.Header>
        <Flex>
          <Accordion.Trigger className={styles.faqCategoryTrigger}>
            <TextV2 textStyle={"Headline S"}>{props.faq.question}</TextV2>
            <PlusIcon aria-hidden className={styles.chevronIcon} />
          </Accordion.Trigger>
        </Flex>
      </Accordion.Header>
      <Accordion.Content className={styles.accordionContent}>
        <Box className={styles.accordionAnswer}>
          <TextV2
            textStyle={"Body M"}
            dangerouslySetInnerHTML={{ __html: props.faq.answer }}
          />
        </Box>
      </Accordion.Content>
    </Accordion.Item>
  );
}

export { FaqSection, FaqSections, FrequentlyAskedQuestion };
