import { Text as RadixText } from "@radix-ui/themes";

type RadixTextProps = React.ComponentProps<typeof RadixText>;

type CustomTextProps = {
  light?: true;
  tiny?: true;
  large?: true;
};

// Combine Radix props with your custom props
type TextProps = CustomTextProps & RadixTextProps;

function Text({ light, tiny, large, ...restProps }: TextProps) {
  let size: RadixTextProps["size"] = "3";
  if (tiny) size = "2";
  if (large) size = "4";

  let color: RadixTextProps["color"] = undefined;
  if (light) color = "gray";

  // Combine custom props with Radix props
  const textProps = {
    size,
    color,
    ...restProps, // Spread the rest of the Radix props here
  };

  return <RadixText {...textProps}>{restProps.children}</RadixText>;
}

export { Text };
