// Keep in line with breakpoints CSS module
const BREAKPOINTS = {
  sm: 640, // Small screens (e.g., phones)
  md: 768, // Medium screens (e.g., tablets)
  lg: 1024, // Large screens (e.g., laptops)
  xl: 1280, // Extra-large screens (e.g., desktops)
};

export const QUERIES = {
  mobile_sm: `(max-width: ${BREAKPOINTS.sm - 1}px)`,
  mobile: `(max-width: ${BREAKPOINTS.md - 1}px)`, // Updated mobile breakpoint
  largerThanMobile: `(min-width: ${BREAKPOINTS.md}px)`,
  tablet: `(min-width: ${BREAKPOINTS.md}px) and (max-width: ${
    BREAKPOINTS.lg - 1
  }px)`,
  smallerThanDesktop: `(max-width: ${BREAKPOINTS.lg - 1}px)`,
  desktop: `(min-width: ${BREAKPOINTS.lg}px)`,
};

export default BREAKPOINTS;
