"use client";

import { Flex, IconButton } from "@radix-ui/themes"; // Import SCSS module for item
import classNames from "classnames";
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from "embla-carousel-react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import styles from "./Carousel.module.scss"; // Import SCSS module for carousel
import {
  ComponentProps,
  createContext,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  KeyboardEvent,
  FC,
} from "react";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

/**
 * This is a carousel that is based on Shadcn Carousel
 * https://ui.shadcn.com/docs/components/carousel
 * The changes made are:
 * - do not position the carousel buttons, but allow the caller to do it
 * - change it so that each item takes up only as much space as needed
 * (note if we have to change this, lets make it more dynamic and allow
 * multiple behaviors)
 */

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
  slidesToScroll?: number;
  loop?: boolean;
  autoPlay?: boolean;
  interval?: number;
  stopOnHover?: boolean;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  currentIndex: number;
  totalSlides: number;
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
} & CarouselProps;

interface CarouselArrowsProps extends ComponentProps<typeof IconButton> {
  isGreenLayout?: boolean;
}

interface CarouselPaginationDotsProps {
  className?: string;
  isGreenLayout?: boolean;
}

const CarouselContext = createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const Carousel = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = "horizontal",
      opts,
      setApi,
      plugins,
      className,
      children,
      slidesToScroll = 1,
      loop = false,
      autoPlay = false,
      interval = 5000,
      stopOnHover = false,
      ...props
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
        slidesToScroll,
        loop,
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalSlides, setTotalSlides] = useState(0);
    const [isPlaying, setIsPlaying] = useState(autoPlay);

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
      setCurrentIndex(api.selectedScrollSnap());
      setTotalSlides(api.slideNodes().length);
    }, []);

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    // Handle pause/resume on hover
    const handleMouseEnter = () => {
      if (stopOnHover) setIsPlaying(false);
    };
    const handleMouseLeave = () => {
      if (stopOnHover) setIsPlaying(true);
    };

    // Handle autoplay
    useEffect(() => {
      if (!autoPlay) return;
      const play = () => {
        if (isPlaying) scrollNext();
      };
      const playInterval = setInterval(play, interval);
      return () => clearInterval(playInterval);
    }, [isPlaying, autoPlay, interval, scrollNext]);

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          currentIndex,
          totalSlides,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={`${styles.carousel} ${className}`}
          role="region"
          aria-roledescription="carousel"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = "Carousel";

const CarouselContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef } = useCarousel();

  return (
    <div ref={carouselRef} className={styles.content}>
      <div ref={ref} className={`${styles.inner}  ${className}`} {...props} />
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        className={`${styles.item}  ${className}`}
        {...props}
      />
    );
  },
);
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = forwardRef<HTMLButtonElement, CarouselArrowsProps>(
  (
    {
      className,
      variant = "soft",
      size = "2",
      radius = "full",
      isGreenLayout = false,
      ...props
    },
    ref,
  ) => {
    const { scrollPrev, canScrollPrev } = useCarousel();

    return (
      <IconButton
        ref={ref}
        variant={variant}
        size={size}
        radius={radius}
        className={classNames(className, styles.arrow, {
          [styles.isGreenLayout]: isGreenLayout,
          [styles.isHidden]: !canScrollPrev,
        })}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...props}
      >
        <ChevronLeft size={20} />
        <span className="sr-only">Previous slide</span>
      </IconButton>
    );
  },
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = forwardRef<HTMLButtonElement, CarouselArrowsProps>(
  (
    {
      className,
      variant = "soft",
      size = "2",
      radius = "full",
      isGreenLayout = false,
      ...props
    },
    ref,
  ) => {
    const { scrollNext, canScrollNext } = useCarousel();

    return (
      <IconButton
        ref={ref}
        variant={variant}
        size={size}
        radius={radius}
        className={classNames(className, styles.arrow, {
          [styles.isGreenLayout]: isGreenLayout,
          [styles.isHidden]: !canScrollNext,
        })}
        disabled={!canScrollNext}
        onClick={scrollNext}
        {...props}
      >
        <ChevronRight size={20} />
        <span className="sr-only">Next slide</span>
      </IconButton>
    );
  },
);
CarouselNext.displayName = "CarouselNext";

const CarouselPaginationDots: FC<CarouselPaginationDotsProps> = ({
  className,
  isGreenLayout = false,
}) => {
  const { api, currentIndex, totalSlides } = useCarousel();
  const visibleDots = 5;
  const halfVisibleDots = Math.floor(visibleDots / 2);

  // Calculate offset to center the active dot within the visible range
  const offset = useMemo(() => {
    if (currentIndex <= halfVisibleDots) {
      return 0;
    }
    if (currentIndex >= totalSlides - halfVisibleDots) return;
    return (currentIndex - halfVisibleDots) * -20;
  }, [currentIndex, totalSlides, halfVisibleDots]);

  if (!api || totalSlides <= 1) return null;

  return (
    <Flex
      className={classNames(className, styles.paginationDotContainer, {
        [styles.isCentered]: totalSlides <= visibleDots,
        [styles.isGreenLayout]: isGreenLayout,
      })}
    >
      <Flex
        className={styles.paginationDotWrapper}
        style={{ transform: `translateX(${offset}px)` }}
      >
        {Array.from({ length: totalSlides }, (_, index) => (
          <IconButton
            key={index}
            onClick={() => api.scrollTo(index)}
            size={"1"}
            radius={"full"}
            variant={index === currentIndex ? "solid" : "soft"}
            color={"gray"}
            aria-label={`Slide ${index + 1}`}
            className={classNames(styles.paginationDot, {
              [styles.activePaginationDot]: index === currentIndex,
            })}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselPaginationDots,
  type CarouselApi,
};
