import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Button, Flex } from "@radix-ui/themes";
import React, { forwardRef } from "react";
import { TextV2 } from "@/design-system/components/text/TextV2";
import styles from "./Filter.module.scss";
import classNames from "classnames";

const FilterButton = forwardRef<
  HTMLButtonElement,
  {
    label: string;
    appliedCount: number;
    onClick?: () => void;
    style?: React.CSSProperties;
    className?: string;
  }
>(({ label, appliedCount, onClick, style, className }, ref) => {
  return (
    <Button
      ref={ref} // Forwarding the ref here
      radius={"full"}
      className={classNames(styles.filterButton, className)}
      size={"3"}
      onClick={onClick}
      style={style}
    >
      <Flex
        direction={"row"}
        align={"center"}
        gap={"3"}
        justify={"center"}
        className={"fullWidth"}
      >
        <FilterCount count={appliedCount} />
        {label}
        <ChevronDownIcon width={24} height={24} />
      </Flex>
    </Button>
  );
});

FilterButton.displayName = "FilterButton";

function FilterCount({ count }: { count: number }) {
  return (
    <Flex
      align={"center"}
      justify={"center"}
      style={{
        backgroundColor: "var(--colorV2-grey-light)",
        borderRadius: "50%",
        display: count > 0 ? "flex" : "none",
        width: "20px",
        height: "20px",
      }}
    >
      <TextV2 textStyle={"Label XS"}>{count}</TextV2>
    </Flex>
  );
}

// Update the export to use the forwardRef wrapped component
export default FilterButton;
